<template>
  <div v-if="dialogVisible">
    <el-dialog title="收文详情" :visible.sync="dialogVisible" width="60%">
      <div class="addbox">
        <el-tabs v-model="activeName">
          <el-tab-pane label="文单信息" name="wendan">
            <div class="left_info" ref="print">
              <div class="mytabble" style="width: 100%">
                <div class="innerbox">
                  <div class="title">城北区人民检察院公文处理单</div>
                  <div class="tablebox">
                    <div class="line">
                      <div class="namebox">收文编号</div>
                      <div class="value" style="width: 10%">
                        {{ info.swzh }}
                      </div>
                      <div class="namebox">收文日期</div>
                      <div class="value" style="width: 18%; border-right: none">
                        {{ info.swrq }}
                      </div>
                      <div class="namebox">密级</div>
                      <div class="value" style="width: 18%; border-right: none">
                        {{ info.mj }}
                      </div>
                      <div class="namebox">存档意见</div>
                      <div class="value" style="width: 18%">
                        {{ info.cdyj }}
                      </div>
                    </div>
                    <div class="line">
                      <div class="namebox">来文单位</div>
                      <div class="value" style="width: 37%">
                        {{ info.lwdw }}
                      </div>
                      <div class="namebox">来文号</div>
                      <div class="value" style="width: 45%">
                        {{ info.lwzh }}
                      </div>
                    </div>
                    <div class="line" style="height: 100px">
                      <div class="namebox">文件标题</div>
                      <div
                        class="value"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        {{ info.name }}
                      </div>
                    </div>
                    <div class="line" style="height: 70px">
                      <div class="namebox fdc">
                        <span class="h18">办公室</span>
                        <span class="h18">拟办意见</span>
                      </div>
                      <div
                        class="value jsfa aifs"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        {{ info.nb && info.nb.yijian }}
                        <img
                          v-if="info.nb && info.nb.yijian"
                          :src="info.nb && info.nb.url"
                          style="width: 75px; height: 30px; margin: 0 10px"
                          alt=""
                        />
                        {{ info.nb && info.nb.addtime }}
                      </div>
                    </div>
                    <div class="line" style="height: 200px">
                      <div class="namebox fdc">
                        <span class="h18">分管领</span>
                        <span class="h18">导意见</span>
                      </div>
                      <div class="value jsfa aifs fww" style="width: 37%">
                        {{ info.yj && info.yj.yijian }}
                        <img
                          v-if="info.yj && info.yj.yijian"
                          :src="info.yj && info.yj.url"
                          style="width: 75px; height: 30px; margin: 0 10px"
                          alt=""
                        />
                        {{ info.yj && info.yj.addtime }}
                      </div>
                      <div class="namebox fdc">
                        <span class="h18">检察长</span>
                        <span class="h18">批示</span>
                      </div>
                      <div class="value jsfa aifs fww" style="width: 45%">
                        {{ info.ps && info.ps.yijian }}
                        <img
                          v-if="info.ps && info.ps.yijian"
                          :src="info.ps && info.ps.url"
                          style="width: 75px; height: 30px; margin: 0 10px"
                          alt=""
                        />
                        {{ info.ps && info.ps.addtime }}
                      </div>
                    </div>
                    <div class="line" style="height: 335px">
                      <div class="namebox fdc">
                        <span class="h18">院领导</span>
                        <span class="h18">意见</span>
                      </div>
                      <div
                        class="value fdc pd0 jsfa"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        <div
                          class="value2 jsfa aifs"
                          style="
                            height: 67px;
                            line-height: 18px;
                            flex-direction: row;
                          "
                        >
                          {{ info.yld && info.yld[0] && info.yld[0].yijian }}
                          <img
                            v-if="info.yld && info.yld[0] && info.yld[0].yijian"
                            :src="info.yld && info.yld[0] && info.yld[0].url"
                            style="width: 138px; height: 55px; margin: 0 10px"
                            alt=""
                          />
                          {{ info.yld && info.yld[0] && info.yld[0].addtime }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            border-bottom: 1px solid #333;
                            height: 67px;
                            line-height: 18px;
                            flex-direction: row;
                          "
                        >
                          {{ info.yld && info.yld[1] && info.yld[1].yijian }}
                          <img
                            v-if="info.yld && info.yld[1] && info.yld[1].yijian"
                            :src="info.yld && info.yld[1] && info.yld[1].url"
                            style="height: 100%; margin: 0 10px"
                            alt=""
                          />
                          {{ info.yld && info.yld[1] && info.yld[1].addtime }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            height: 67px;
                            line-height: 18px;
                            flex-direction: row;
                          "
                        >
                          {{ info.yld && info.yld[2] && info.yld[2].yijian }}
                          <img
                            v-if="info.yld && info.yld[2] && info.yld[2].yijian"
                            :src="info.yld && info.yld[2] && info.yld[2].url"
                            style="height: 100%; margin: 0 10px"
                            alt=""
                          />
                          {{ info.yld && info.yld[2] && info.yld[2].addtime }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            height: 67px;
                            line-height: 18px;
                            flex-direction: row;
                          "
                        >
                          {{ info.yld && info.yld[3] && info.yld[3].yijian }}
                          <img
                            v-if="info.yld && info.yld[3] && info.yld[3].yijian"
                            :src="info.yld && info.yld[3] && info.yld[3].url"
                            style="height: 100%; margin: 0 10px"
                            alt=""
                          />
                          {{ info.yld && info.yld[3] && info.yld[3].addtime }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            height: 67px;
                            border-bottom: none;
                            line-height: 18px;
                            flex-direction: row;
                          "
                        >
                          {{ info.yld && info.yld[4] && info.yld[4].yijian }}
                          <img
                            v-if="info.yld && info.yld[4] && info.yld[4].yijian"
                            :src="info.yld && info.yld[4] && info.yld[4].url"
                            style="height: 100%; margin: 0 10px"
                            alt=""
                          />
                          {{ info.yld && info.yld[4] && info.yld[4].addtime }}
                        </div>
                      </div>
                    </div>
                    <div class="line" style="height: 130px">
                      <div class="namebox fdc">
                        <span>传</span>
                        <span>阅</span>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第一检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 41 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 41 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 41 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 41 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 41 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第二检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 42 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 42 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 42 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 42 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 42 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第三检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 43 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 43 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 43 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 43 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 43 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第四检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 44 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 44 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 44 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 44 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 44 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第五检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 45 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 45 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 45 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 45 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 45 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">综合业</span>
                          <span class="h18">务部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 46 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 46 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 46 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 46 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 46 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span>政治部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 47 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 47 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 47 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 47 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 47 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span>办公室</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 40 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                          <img
                            :src="
                              info.cy &&
                              info.cy.find((item) => {
                                return item.useradmin == 40 && item.state == 2;
                              }) &&
                              info.cy.find((item) => {
                                return item.useradmin == 40 && item.state == 2;
                              }).url
                            "
                            style="width: 100%; margin: 0 10px"
                            alt=""
                          />
                          <span style="font-size: 9px">{{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 40 && item.state == 2;
                            }) &&
                            info.cy.find((item) => {
                              return item.useradmin == 40 && item.state == 2;
                            }).addtime
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span></span>
                        </div>
                        <div class="value2" style="height: 80px">
                          <span></span>
                        </div>
                      </div>
                      <div class="value fdc jsfa pd0" style="width: 9.11%">
                        <div class="value2" style="height: 50px">
                          <span></span>
                        </div>
                        <div class="value2" style="height: 80px">
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div class="line" style="height: 90px">
                      <div class="namebox fdc">
                        <span class="h18">承办</span>
                        <span class="h18">结果</span>
                      </div>
                      <div
                        class="value"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        {{ info.blr && info.blr[0] && info.blr[0].yijian }}
                      </div>
                    </div>
                    <div class="line" style="height: 70px">
                      <div class="namebox">
                        <span>备注</span>
                      </div>
                      <div
                        class="value fdc"
                        style="
                          width: 91%;
                          border-right: 1px solid #333;
                          justify-content: flex-start;
                        "
                      >
                        <span class="tal h18" style=""
                          >公文办理完毕后务必及时退回机要室，承办部门或者个人不得私自留存。</span
                        >
                        <span class="tal h18"
                          >承办部门或者个人办理涉密公文，不得私自复印、留存、扩大知秘范围，</span
                        >
                        <span class="tal h18"
                          >注:我院机要文件多为黑白文头，此为原件，请勿混淆。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="title">
								正文
							</div>
							<div v-html="info.zhengwen" style="padding: 15px;line-height: 25px;">
								
							</div> -->
              <div
                style="
                  width: 100%;
                  display: flex;
                  margin-bottom: 10px;
                  margin-top: 20px;
                "
              >
                <div>文单附件：</div>
                <div style="width: 80%">
                  <div
                    style="
                      color: #409eff;
                      margin-bottom: 5px;
                      cursor: pointer;
                      width: 80%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    @click="open(info.fj_url)"
                  >
                    {{ info.filename }}
                  </div>
                </div>
              </div>
              <!-- <div style="width: 100%; display: flex; margin-bottom: 10px">
                <div>办理结果附件：</div>
                <div style="width: 80%">
                  <div
                    v-for="(item2, index2) in info.cb"
                    v-if="item2.blfj"
                    :key="index2"
                    style="
                      color: #409eff;
                      margin-bottom: 5px;
                      cursor: pointer;
                      width: 80%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    @click="open(item2.blfj.fullurl)"
                  >
                    {{ item2.blfj.filename }}
                  </div>
                </div>
              </div> -->
            </div>
          </el-tab-pane>
          <el-tab-pane label="流程信息" name="liuc">
            <div style="width: 100%; max-height: 65vh; overflow-y: auto">
              <el-steps
                direction="vertical"
                :active="info.type == 7 ? listlog.length : listlog.length - 1"
              >
                <el-step
                  :title="item.yijian"
                  v-for="(item, index) in setdaoxu(listlog)"
                  :key="index"
                >
                  <div slot="description">
                    <div
                      v-if="info.type != 7"
                      :style="{
                        color:
                          index == listlog.length - 1
                            ? '#ccc'
                            : 'rgb(60, 156, 255)',
                      }"
                    >
                      {{ index == listlog.length - 1 ? "待处理" : "通过" }}
                    </div>
                    <div
                      v-if="info.type == 7"
                      :style="{ color: 'rgb(60, 156, 255)' }"
                    >
                      通过
                    </div>
                    <div class="infoboxsetp">
                      <div class="info_linesetp">
                        <div class="namesetp">办理人：</div>
                        <div class="valuesetp">
                          {{ item.user_name }}
                        </div>
                      </div>
                      <div class="info_linesetp">
                        <div class="namesetp">办理时间：</div>
                        <div class="valuesetp">
                          {{ item.addtime }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-step>
              </el-steps>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="btnbox">
        <el-button
          type=""
          size="mini"
          class="font14"
          @click="dialogVisible = false"
          >关闭</el-button
        >
        <el-button v-show="showprint" type="success" size="mini" @click="print"
          >打 印</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showprint: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      activeName: "wendan",
      dialogVisible: false,
      info: "",
      listlog: [],
    };
  },
  watch: {
    info(newv, ov) {
      // if(newv.type!=7){
      // 	let obj = {
      // 		addtime:'',
      // 		user_name:newv.type==1?newv.ybuser_name:newv.type==2?newv.psuser_name:newv.type==3?newv.user_name:(newv.type==4||newv.type==5)?newv.bl_name:newv.type==6?newv.user_name:newv.type==7?newv.user_name:'',
      // 		yijian:newv.type==1?'待拟办':newv.type==2?'待批示':newv.type==3?'已批示待办理':newv.type==4?'待办理':newv.type==5?'办理中':newv.type==6?'已办理待归档':'已归档'
      // 	}
      // 	newv.log.unshift(obj)
      // }
      // this.listlog = newv.log
      // console.log(this.listlog)
    },
  },
  methods: {
    print() {
      this.$printPage(this.$refs.print);
    },
    changetime(time) {
      const date = new Date(time * 1000);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    setdaoxu(arr) {
      return arr.slice().reverse();
    },
    open(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_line {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .tlename {
    width: 120px;
    font-size: 14px;

    text-align: right;
    color: #333;
  }

  .bticon {
    width: 10px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 34px;
    font-size: 26px;
    color: red;
  }
}

.infobox {
  width: 100%;
  padding-left: 50px;
  padding-right: 30px;

  .contbox {
    display: flex;
    padding: 20px 40px;
    height: 750px;
    transition: all 0.5s;
    overflow: hidden;
  }

  .colosecont {
    height: 0;
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    color: #ec4a4a;
    border-bottom: 1px solid #ec4a4a;
  }
}

.addbox {
  width: 100%;
  overflow-y: auto;
  padding: 30px;
  background-color: #fff;
  max-height: 80vh;
}

.add_cont {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.btnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  border-top: 1px solid #eee;
}

::v-deep .el-dialog {
  margin: 0 !important;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
  padding: 10px;
  padding-top: 0px;
}

::v-deep .el-tabs--border-card {
  height: 100%;
}

::v-deep .el-tabs--border-card {
  box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
  background-color: #fff;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.innerbox {
  width: 100%;
  background-color: #fff;
  padding: 20px 10px;
}
.title {
  font-size: 22px;
  color: #333;
  width: 100%;
  text-align: center;
}
.line {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
}
.namebox {
  height: inherit;
  width: 9%;
  border: 1px solid #333;
  padding: 7px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 100%;
  }
}
.namebox:nth-last-child(1) {
  border-right: 1px solid #333;
}
.value {
  height: inherit;
  width: 35%;
  padding: 7px;
  text-align: center;
  border: 1px solid #333;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  span {
    width: 100%;
  }
}
.value:nth-child(2) {
  border-right: none;
}
.line:nth-last-child(1) {
  border-bottom: 1px solid #333;
}
.btm_btn {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tablebox {
  margin-top: 15px;
}

.infoboxsetp {
  margin: 5px 0;
  padding: 10px;
  box-shadow: 0 0px 5px #eee;
  border-radius: 5px;
  width: 45%;
  .info_linesetp {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 5px 0;
    .namesetp {
      color: #999;
    }
    .valuesetp {
      color: #333;
    }
  }
}

::v-deep .el-step__description {
  padding-right: 10% !important;
}

.value2 {
  width: 100%;
  height: inherit;
  border-bottom: 1px solid #333;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.value2:nth-child(2) {
  border-bottom: none;
}

.fdc {
  flex-direction: column;
}
.jsfa {
  justify-content: flex-start;
}
.fww {
  flex-wrap: wrap;
}
.aifs {
  align-items: flex-start;
}
.pd0 {
  padding: 0;
}
.tal {
  text-align: left;
}
.h18 {
  height: 18px;
  line-height: 18px;
}

</style>
